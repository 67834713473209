import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import MobileNavbar from './components/mobileNavbar/MobileNavbar';
import Home from './pages/Home/Home';
import School from './pages/School/School';
import Cursos from './pages/Cursos/Cursos';
import Empresas from './pages/Empresas/Empresas';
import Adultos from './pages/Adultos/Adultos';
import WhatsAppIcon from './assets/whatsapp-icon.svg';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <MobileNavbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/School" element={<School />} />
          <Route path="/Cursos" element={<Cursos />} />
          <Route path="/Empresas" element={<Empresas />} />
          <Route path="/Adultos" element={<Adultos />} />
        </Routes>
        
        {/* WhatsApp Button Container */}
        <div className="whatsapp-button-container">
          <a
            href="https://wa.me/message/CYQZH2SKT227H1"
            className="whatsapp-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={WhatsAppIcon} alt="WhatsApp" className="whatsapp-icon" />
            <span>¡Háblanos por WhatsApp!</span>
          </a>
        </div>
        
      </div>
    </Router>
  );
}

export default App;
