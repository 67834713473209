import React from 'react';
import './footer.css';
import InstagramIcon from './instagram.svg';  // Replace with the actual image paths
import TiktokIcon from './tiktok.svg';
import LinkedinIcon from './linkedin.svg';
import FacebookIcon from './facebook.svg';
import YoutubeIcon from './youtube.svg';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-column">
          <h3 className="footer-title">Nuestros Cursos</h3>
          <ul>
            <li><a href="/curso-ingles" target="_blank" rel="noopener noreferrer">Curso de Inglés</a></li>
            <li><a href="/curso-ninos" target="_blank" rel="noopener noreferrer">Curso de Inglés para niños y adolescentes</a></li>
            <li><a href="/curso-adultos" target="_blank" rel="noopener noreferrer">Curso de Inglés para jóvenes y adultos</a></li>
            <li><a href="/curso-empresas" target="_blank" rel="noopener noreferrer">Curso de Inglés para empresas</a></li>
          </ul>
        </div>

        <div className="footer-column">
          <h3 className="footer-title">Para Alumnos</h3>
          <ul>
            <li><a href="/aula-virtual" target="_blank" rel="noopener noreferrer">Aula Virtual</a></li>
            <li><a href="/comunidad" target="_blank" rel="noopener noreferrer">Comunidad Englishmate</a></li>
            <li><a href="/tutoriales" target="_blank" rel="noopener noreferrer">Tutoriales de ingreso</a></li>
            <li><a href="/chat-soporte" target="_blank" rel="noopener noreferrer">Chat soporte alumnos</a></li>
          </ul>
        </div>

        <div className="footer-column">
          <h3 className="footer-title">Síguenos!</h3>
          <div className="social-links">
            <a href="https://www.instagram.com/inglesmate.ed/" target="_blank" rel="noopener noreferrer">
              <img src={InstagramIcon} alt="Instagram" />
            </a>
            <a href="https://www.tiktok.com/@englishmate.edu" target="_blank" rel="noopener noreferrer">
              <img src={TiktokIcon} alt="Tiktok" />
            </a>
            <a href="https://www.linkedin.com/company/englishmateedu/about/" target="_blank" rel="noopener noreferrer">
              <img src={LinkedinIcon} alt="Linkedin" />
            </a>
            <a href="https://www.facebook.com/people/Englishmate/61565042608460/" target="_blank" rel="noopener noreferrer">
              <img src={FacebookIcon} alt="Facebook" />
            </a>
            <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
              <img src={YoutubeIcon} alt="Youtube" />
            </a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; Englishmate SAC 2024</p>
      </div>
    </footer>
  );
}

export default Footer;


