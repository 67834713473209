import React from 'react';
import ProximamenteSection from '../../components/proximamente/proximamente';
import SecondaryNavbar from '../../components/secondaryNavbar/SecondaryNavbar';

function Empresas () {
  return (
    <div>
      <ProximamenteSection />
      <SecondaryNavbar />
    </div>
  );
}

export default Empresas;
