import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './secondaryNavbar.css';

function SecondaryNavbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation(); // Hook to get the current path

  useEffect(() => {
    const handleScroll = () => {
      const triggerHeight = window.innerHeight * 0.85;
      setIsScrolled(window.scrollY > triggerHeight);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className="secondary-navbar">
      <div className={`secondary-navbar-links ${isScrolled ? 'move-left' : 'move-right'}`}>
        <Link 
          to="/adultos" 
          className={`secondary-navbar-link ${location.pathname === '/adultos' ? 'active' : ''}`}
        >
          Jovenes & Adultos
        </Link>
        <Link 
          to="/school" 
          className={`secondary-navbar-link ${location.pathname === '/school' ? 'active' : ''}`}
        >
          Niños & Adolescentes
        </Link>
        <Link 
          to="/empresas" 
          className={`secondary-navbar-link ${location.pathname === '/empresas' ? 'active' : ''}`}
        >
          Para Empresas
        </Link>
        <Link 
          to="/cursos" 
          className={`secondary-navbar-link ${location.pathname === '/cursos' ? 'active' : ''}`}
        >
          Nuestros Cursos
        </Link>
      </div>
    </nav>
  );
}

export default SecondaryNavbar;
