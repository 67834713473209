import React, { useEffect, useState } from 'react';
import './cursoSection.css';
import './cursoSectionS.css';
import './cursoSectionM.css';
import './cursoSectionL.css';
import ChicaLaptop from '../../assets/ChicaLaptop.jpg'
import PreparacionExamenes from '../../assets/PreparacionExamenes.jpg'
import SeguimientoAlumno from '../../assets/SeguimientoAlumno.png'



const CursoSection = () => {

  const [isVisible, setIsVisible] = useState(true);
  
  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector('.curso-section');
      const scrollPosition = window.scrollY;
      const sectionTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;
      const sectionBottom = sectionTop + sectionHeight;
  
      if (scrollPosition > sectionTop - window.innerHeight && scrollPosition < sectionBottom) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  

    return (
        <div className={`curso-section animate__animated ${isVisible ? 'animate__fadeIn' : ''}`}>
            <div>
              <h2 className='curso-h2'>¿Por qué elegir estudiar inglés con <span className='curso-h2-color'>englishmate</span>?</h2>
            </div>
            <div className="grid-container">
                <div className="educacion-box">
                    <img src={ChicaLaptop} alt="Chica estudiando online" className="educacion-image"/>
                    <div className="text-container">
                        <h3 className="curso-title">Aprende inglés rápido con la mejor educación online</h3>
                        <p className="curso-text">
                          Estudia inglés online con <span className='curso-text-color'>englishmate</span> y accede a una plataforma educativa de alta calidad que se adapta a tus necesidades. <br /><br />
                          Nuestros cursos de inglés online están diseñados para ofrecer una experiencia de aprendizaje completa y efectiva. Además, disfruta de sesiones de repaso gratuitas para reforzar tu aprendizaje, participa en nuestros <span className='curso-text-color'>Speaking Weekends</span>, donde podrás practicar la conversación en un ambiente relajado, y accede a múltiples talleres gratuitos sobre diversos temas para ampliar aún más tus conocimientos.
                        </p>
                    </div>
                </div>
                <div className="examenes-box">
                    <img src={PreparacionExamenes} alt="Chica preparandose para exámenes de inglés" className="examenes-image"/>
                    <div className="text-container">
                        <h4 className="curso-title">Preparación para exámenes internacionales incluida</h4>
                        <p className="curso-text">En <span className='curso-text-color'>englishmate</span>, la preparación para exámenes internacionales está incluida en nuestros cursos, sin costos adicionales. Te preparamos para exámenes como <span className='curso-text-color'>IELTS, FCE, CAE y TOEFL</span>, utilizando simulacros (mocks) y talleres gratuitos. Creemos que la certificación de tu inglés debe ser un estándar, no un extra.</p>
                    </div>
                </div>
                <div className="seguimiento-box">
                    <img src={SeguimientoAlumno} alt="Seguimiento personalizado" className="box-image"/>
                    <div className="text-container">
                        <h4 className="curso-title">Seguimiento personalizado</h4>
                        <p className="curso-text">Ayuda personalizada vía <span className='curso-text-color'>WhatsApp y Discord</span>. Te apoyamos en cada paso para asegurar tu éxito en inglés.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CursoSection;

