import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';
import 'animate.css';
import EnglishmateLogo from '../../assets/EnglishmateLogo.svg';

function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const [showSecondButton, setShowSecondButton] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = window.innerHeight * 0.70;
      if (window.scrollY > scrollHeight) {
        setScrolled(true);
        setShowSecondButton(true);
        setAnimateOut(false);
      } else {
        setScrolled(false);
        setAnimateOut(true);
        setTimeout(() => {
          setShowSecondButton(false);
        }, 150); // Adjust the delay to match the duration of the zoomOut animation
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={scrolled ? 'scrolled' : ''}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Link to="./">
          <img src={EnglishmateLogo} alt="EnglishMate Logo" className="navbar-logo" />
        </Link>
      </div>
      <div className="buttons-navbar" style={{ display: 'flex', alignItems: 'center' }}>
        {showSecondButton && (
          <a href="https://aulavirtual.englishmate.com.pe" target="_blank" rel="noopener noreferrer">
            <button className={`navbar-button navbar-button-secondary animate__animated ${animateOut ? 'animate__zoomOut' : 'animate__zoomIn'}`}>
              Acceso Estudiantes
            </button>
          </a>
        )}
        <a href="https://wa.me/message/CYQZH2SKT227H1" target="_blank" rel="noopener noreferrer">
          <button className="navbar-button">
            Matricúlate Ya
          </button>
        </a>
      </div>
    </nav>
  );
}

export default Navbar;





