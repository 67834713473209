import React, { useEffect, useState } from 'react';
import './aprendeSection.css';
import ClasesVirtuales from '../../assets/ClasesVirtuales.svg';
import Profesores from '../../assets/Profesores.svg';
import Speaking from '../../assets/Speaking.svg';
import Certificado from '../../assets/Certificado.svg';
import 'animate.css';

const AprendeSection = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector('.aprende-section');
      const scrollPosition = window.scrollY;
      const sectionTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;
      const sectionBottom = sectionTop + sectionHeight;

      if (scrollPosition > sectionTop - window.innerHeight && scrollPosition < sectionBottom) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className={`aprende-section animate__animated ${isVisible ? 'animate__fadeIn animate__delay-0.6s' : ''}`}>
      <div className="aprende-container">
        <h2 className="aprende-title">
          Aprende, mejora y certifica tu inglés <br />
          <span className="highlight-text">Conviértete en un mate con englishmate</span>
        </h2>
        <p className="aprende-subtitle">
            Estudia inglés con <span className="highlight-box">englishmate</span> y forma parte de
            <span className="mobile-hide"><br /></span> una comunidad enfocada en tu progreso y éxito.
        </p>
        <div className="aprende-features">
          <div className="feature-item">
            <img src={ClasesVirtuales} alt="Clases 24/7" className="feature-icon" />
            <p>Clases online en vivo</p>
          </div>
          <div className="feature-item">
            <img src={Profesores} alt="Profesores nativos" className="feature-icon" />
            <p>Profesores con nivel nativo</p>
          </div>
          <div className="feature-item">
            <img src={Speaking} alt="Preparación para TOEFL, TOEIC, IELTS" className="feature-icon" />
            <p>Talleres gratis de speaking</p>
          </div>
          <div className="feature-item">
            <img src={Certificado} alt="Certificación en cada nivel" className="feature-icon" />
            <p>Certificado en cada nivel</p>
          </div>
        </div>
        <div className="linea-aprende"/>
      </div>
    </section>
  );
};

export default AprendeSection;
