import React from 'react';
import './proximamente.css';
import ComingSoon from './ComingSoon.gif'; 

function ProximamenteSection() {
  return (
    <div className="proximamente-section">
      <div className="proximamente-text">
        Estamos trabajando en este sitio, vuelve pronto
      </div>
      <div className="proximamente-image">
        <img src={ComingSoon} alt="Imagen cohete despegando" />
      </div>
    </div>
  );
}

export default ProximamenteSection;
