import React from 'react';
import './coursesCard.css';
import './coursesCardResponsive.css' // Ensure your CSS file path is correct

function DiscountCard({
  discountText,
  courseTitle,
  duration,
  features,
  plan,
  originalPrice,
  specialOffer,
  priceLabel,
  price,
  total,
  buttonText,
  buttonLink
}) {
  return (
    <div className="card">
      <div className="discount-badge">
        <div className="discount-text">
          {discountText}
        </div>
      </div>
      <div className="card-content">
        <h2>{courseTitle}</h2>
        <p className="duration">{duration}</p>
        <ul>
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
        <hr />
        <p className="plan">
          {plan} <span className="line-through">{originalPrice}</span>
        </p>
        <p className="special-offer">{specialOffer}</p>
        <p className="price-label">{priceLabel}</p>
        <p className="price">{price}</p>
        <p className="total">{total}</p>
        <button
          className="cta-button"
          onClick={() => window.open(buttonLink, '_blank')}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
}

export default DiscountCard;
