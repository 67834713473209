import React from 'react';
import SecondaryNavbar from '../../components/secondaryNavbar/SecondaryNavbar';
import ProximamenteSection from '../../components/proximamente/proximamente';
import './school.css'

function Kids() {
  return (
    <div>
      <ProximamenteSection />
      <SecondaryNavbar />
    </div>
  );
}

export default Kids;
